import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=locale]]/about": [33,[10]],
		"/(dex)/addresses/[id]": [11,[2]],
		"/(dex)/assistant": [~12,[2]],
		"/[[lang=locale]]/blog": [~34,[10]],
		"/[[lang=locale]]/blog/[slug]": [35,[10]],
		"/(dex)/codes-ai": [16,[2,7]],
		"/(dex)/codes-ai/[thread]": [17,[2,7]],
		"/(dex)/codes": [13,[2,3],[,4]],
		"/(dex)/codes/[chapter]": [14,[2,3,5],[,4,6]],
		"/(dex)/codes/[chapter]/[code]": [15,[2,3,5],[,4,6]],
		"/(dex)/company_numbers": [18,[2]],
		"/[[lang=locale]]/contact": [36,[10]],
		"/(dex)/contracts": [19,[2,8]],
		"/(dex)/contracts/invites": [22,[2,8]],
		"/(dex)/contracts/new_from_invite": [24,[2,8]],
		"/(dex)/contracts/new": [23,[2,8]],
		"/(dex)/contracts/signers": [25,[2,8]],
		"/(dex)/contracts/signers/[signer]": [26,[2,8]],
		"/(dex)/contracts/[id]": [20,[2,8]],
		"/(dex)/contracts/[id]/edit": [21,[2,8]],
		"/[[lang=locale]]/docs": [37,[10]],
		"/[[lang=locale]]/docs/[slug]": [38,[10]],
		"/[[lang=locale]]/duty-calc": [39,[10]],
		"/(dex)/edit_declaration/[id]": [27,[2]],
		"/(dex)/email_preview": [28,[2]],
		"/[[lang=locale]]/hs-codes": [40,[10]],
		"/(dex)/mig/dms": [29,[2],[,9]],
		"/(dex)/new_declaration": [30,[2]],
		"/[[lang=locale]]/services": [41,[10]],
		"/[[lang=locale]]/services/[service]": [42,[10]],
		"/[[lang=locale]]/software": [43,[10]],
		"/(dex)/trigger": [31,[2]],
		"/[[lang=locale]]": [32,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';